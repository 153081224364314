<template>
  <div id="app">
    <top-bar class="top-bar"></top-bar>
    <el-container>
      <el-main>
        <router-view />
        <a id="TOPUp" href="#app" :style="_isMobile ? 'display:none':''">
          <img style="width: 100%;height: 100%;" src="@/assets/imgs/top.png" alt="">
        </a>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import topBar from '@/components/topBar.vue'
export default {
  components: { topBar },
  computed: {
    _isMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>

<style lang="scss">
#app {
}
.el-container {
  .el-header {
    padding: 0;
  }
  .el-main {
    padding: 0;
  }
  .top-bar{
    position: sticky;
  }
}
#TOPUp{
  position: fixed;
  right: 45px;
  bottom: 100px;
  width: 60px;
  height: 60px;
  z-index: 99999999;
  border-radius: 600px;
}
</style>
