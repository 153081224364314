<template>
  <div class="top-bar">
    <el-menu
      :default-active="activeIndex"
      class="menu-box"
      :mode="_isMobile ? 'vertical' : 'horizontal'"
      @select="handleSelect"
      background-color="#000"
      text-color="#fff"
      active-text-color="#6670f1"
      :style="{ 'padding-top': isShowMenu ? '4rem' : '0' }"
    >
      <!-- <el-menu
      :default-active="activeIndex"
      class="menu-box"
      :mode="_isMobile ? 'vertical' : 'horizontal'"
      background-color="#000"
      :style="{ 'padding-top': isShowMenu ? '4rem' : '0' }"
    > -->
      <img
        v-if="_isMobile"
        class="icon-btn"
        :src="
          require('@/assets/imgs/' + (isShowMenu ? 'close' : 'open') + '.png')
        "
        @click="isShowMenu = !isShowMenu"
      />
      <img
        class="logo"
        :src="require('@/assets/imgs/logo.png')"
        @click="goHome"
      />
      <template v-if="!_isMobile || isShowMenu">
        <template v-for="(item, index) in menuList">
          <el-submenu
            v-if="item.subMenu.length > 0"
            :index="item.name"
            :key="index"
            :popper-append-to-body="false"
          >
            <template slot="title">{{ item.name }}</template>
            <el-menu-item
              :class="{ active: activeIndex === index + '-' + subindex }"
              :index="subitem.name"
              v-for="(subitem, subindex) in item.subMenu"
              :key="subindex"
              >{{ subitem.name }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            :class="{ active: activeIndex === index.toString() }"
            v-else
            :index="item.name"
            :key="index + '_0'"
          >
            <template v-if="item.href">
              <a :href="item.href" target="_blank">{{ item.name }}</a>
            </template>
            <template v-else>{{ item.name }}</template>
          </el-menu-item>
        </template></template
      >
    </el-menu>
  </div>
</template>

<script>
import '../assets/font/font.css'
export default {
  data () {
    return {
      activeIndex: 'Home',
      menuList: [
        { name: 'Home', url: '/', subMenu: [] },
        {
          name: 'Blues',
          url: '/detailsPage',
          subMenu: [
            { name: 'Assassin' },
            { name: 'EVA-1920' },
            { name: 'Gentle monster' },
            { name: 'Ninja' },
            { name: 'North' },
            { name: 'T10' },
            { name: 'Trochilus(Ritcher/Pop)' }
          ]
        },
        {
          name: 'Chromatic',
          url: '/detailsPage',
          subMenu: [
            { name: 'EVO-0648S' },
            { name: 'GM-0648' },
            { name: 'Luna' },
            { name: 'Quartz' },
            { name: 'Infinity' },
            { name: 'Trochilus(Solo)' }
          ]
        },
        {
          name: 'Video',
          href: 'https://www.youtube.com/channel/UC6ajaAzNPvqwdKQGOzmoqQA',
          subMenu: []
        },
        { name: 'Contact us', url: '/contactPage', subMenu: [] }
      ],
      isShowMenu: false,
      currCheck: 0
    }
  },
  computed: {
    _isMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  },
  mounted () {
    const arr = window.location.search.split('=')
    if (arr.length > 1) {
      this.activeIndex = arr[arr.length - 1]
    } else {
      const path = window.location.pathname
      this.menuList.some((v) => {
        if (v.url === path) {
          this.activeIndex = v.name
        }
        return v.url === path
      })
    }
  },
  methods: {
    goHome () {
      this.$router.push({ path: '/' })
      this.isShowMenu = false
    },
    handleSelect (val, path) {
      if (val === 'Video') {
        window.open(this.menuList[3].href)
      } else {
        if (path.length > 1) {
          this.menuList.some((v) => {
            if (v.name === path[0]) {
              this.$router.push({
                path: v.url,
                query: { name: path[1] }
              })
            }
            return v.name === path[0]
          })
        } else {
          this.menuList.some((v) => {
            if (v.name === val) {
              this.$router.push({ path: v.url })
            }
            return v.name === val
          })
        }
        if (this._isMobile) {
          this.isShowMenu = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar {
  background-color: #000;
  height: 6.5rem;
  .menu-box {
    height: 6.5rem;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    font-family: Thin;
    .logo {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      left: -150px;
      z-index: 1;
      cursor: pointer;
    }
    ::v-deep .el-menu {
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-menu-item {
      height: 100%;
      line-height: 6.5rem;
      font-size: 1.4rem;
      padding: 0 51px;
      a {
        text-decoration: none;
      }
      // &.active {
      //   border: 2px solid red;
      //   color: #6670f1;
      // }
    }
    ::v-deep .el-submenu > .el-submenu__title {
      height: 100%;
      line-height: 6.5rem;
      font-size: 1.4rem;
      border: none;
      padding: 0 51px;
    }
    ::v-deep .el-submenu {
      .el-menu-item {
        height: 50px;
        line-height: 50px;
        font-size: 1.4rem;
        &:hover {
          padding-left: 20px;
          background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.5) 00%,
              rgba(0, 0, 0, 1) 100%
          );
        }
      }
    }
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: 0;
}
@media screen and (max-width: 767px) {
  .top-bar {
    height: 4rem;
    .menu-box {
      height: 4rem;
      width: 100%;
      z-index: 999;
      .logo {
        width: 50px;
        height: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0.75rem;
      }
      .icon-btn {
        color: #fff;
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
      ::v-deep .el-menu-item {
        height: 100%;
        line-height: 5rem;
        font-size: 1rem;
        a {
          text-decoration: none;
          &:visited {
            color: #fff;
          }
        }
      }
      ::v-deep .el-submenu > .el-submenu__title {
        height: 100%;
        line-height: 5rem;
        font-size: 1rem;
        border: none;
      }
      ::v-deep .el-submenu {
        .el-menu-item {
          height: 50px;
          line-height: 50px;
          font-size: 1rem;
          &:hover {
            padding-left: 20px;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.5) 00%,
                rgba(0, 0, 0, 1) 100%
            );
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
</style>
