import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('../views/homePage.vue')
  },
  {
    path: '/detailsPage',
    name: 'detailsPage',
    component: () => import('../views/detailsPage.vue')
  },
  {
    path: '/contactPage',
    name: 'contactPage',
    component: () => import('../views/contactPage.vue')
  },
  {
    path: '/404',
    name: 'nofoundPage',
    component: () => import('../views/nofoundPage.vue')
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
